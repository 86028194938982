<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="form-row mb-2">
          <div class="col-md-12">
            <div class="d-flex float-right" v-if="hub_consumptions.total > 0">
              <a-dropdown placement="bottomRight" :trigger="['click']">
                <button type="button" class="btn btn-outline-success">
                  <i class="fe fe-more-vertical" /> Download
                </button>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a-button class="btn btn-outline-success btn-block mr-2" :loading="pdfDownloadLoader" @click.prevent="pdfDownload"><i class="fa fa-file-pdf-o mr-1"></i> PDF</a-button>
                  </a-menu-item>
                  <a-menu-item>
                    <a-button class="btn btn-outline-success btn-block mr-2" :loading="excelDownloadLoader" @click.prevent="excelDownload"><i class="fa fa-file-excel-o mr-1"></i> Excel</a-button>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
        </div>
        <div class="col-md-4 float-sm-right" style="padding-right: 0px !important;">
          <input type="text" v-model="searchQuery" class="form-control form-control mb-3" placeholder="Type here for search..." style="border: 2px solid #00000054 !important;">
        </div>
        <div class="table-responsive">
          <table class="table table-sm table-hover table-bordered text-center" id="hub_stock_consumption_history">
            <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Unit</th>
              <th>Qty</th>
              <th>Entry Date</th>
              <th>Remarks</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(hub_consumption, index) in resultQuery" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ hub_consumption.product.name }}</td>
              <td>{{ hub_consumption.product.unit }}</td>
              <td>{{ hub_consumption.amount }}</td>
              <td>{{ hub_consumption.entry_date }}</td>
              <td>{{ hub_consumption.consumption_remarks }}</td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="hub_consumptions.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <hr>
          <div class="float-right">
            <pagination class="mt-2" :data="hub_consumptions" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      hub_consumptions: {},
      searchQuery: '',
      loading: false,
      show: false,
      flag: false,
      btnLoading: false,
      pdfDownloadLoader: false,
      excelDownloadLoader: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      search_data: {
        date_range: '',
      },
    }
  },
  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.hub_consumptions.data.filter((item) => {
          return this.searchQuery.toLowerCase().split(' ').every(v => item.product.name.toLowerCase().includes(v)) ||
            this.searchQuery.toLowerCase().split(' ').every(v => item.amount.toString().toLowerCase().includes(v))
        })
      } else {
        return this.hub_consumptions.data
      }
    },
  },
  mounted() { },
  methods: {
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    search() {
      this.loading = true
      this.btnLoading = true
      this.flag = true
      apiClient.post('api/hub/product-stock/consumption-history', this.search_data)
        .then(response => {
          this.loading = false
          this.btnLoading = false
          this.flag = true
          this.hub_consumptions = response.data.hub_consumptions
        })
        .catch(error => {
          console.log(error)
        })
    },
    getResults(page = 1) {
      apiClient.post('api/hub/product-stock/consumption-history?page=' + page, this.search_data)
        .then(response => {
          this.hub_consumptions = response.data.hub_consumptions
        })
    },
    pdfDownload() {
      this.pdfDownloadLoader = true
      apiClient.post('api/hub/consumption/pdf-download', this.search_data, { responseType: 'blob' }).then(response => {
        this.pdfDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'hub_consumption.pdf')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
    excelDownload() {
      this.excelDownloadLoader = true
      apiClient.post('api/hub/consumption/excel-download', this.search_data, { responseType: 'blob' }).then(response => {
        this.excelDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'hub_consumption.xlsx')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>
